import React, { Component } from 'react';
import { Route, Redirect, Router } from 'react-router';
import axios from 'axios';
import Layout  from './components/Layout';
import { Home } from './components/Home';
import { Product } from './routes/product/Product';
import { ProductForm } from './routes/product/ProductForm';
import { Discount } from './routes/discount/Discount';
import { DiscountForm } from './routes/discount/DiscountForm';
import { ProductCategory } from './routes/category/ProductCategory';
import { ProductCategoryForm } from './routes/category/ProductCategoryForm';
import { SiteText } from './routes/siteText/SiteText';
import { SiteTextForm } from './routes/siteText/SiteTextForm';
import { Slider } from './routes/slider/Slider';
import { SliderForm } from './routes/slider/SliderForm';
import { Brand } from './routes/brand/Brand';
import { BrandForm } from './routes/brand/BrandForm';
import { OfferWhatsApp } from './routes/offerWhatsApp/OfferWhatsApp';
import { OfferWhatsAppForm } from './routes/offerWhatsApp/OfferWhatsAppForm';
import { Price } from './routes/price/Price';
import { Order } from './routes/order/Order';
import { OrderForm } from './routes/order/OrderForm';
import { Client } from './routes/client/Client';
import { ClientForm } from './routes/client/ClientForm';
import { drugstore } from './routes/drugstore/drugstore';
import { drugstoreForm } from './routes/drugstore/drugstoreForm';
import { Seller } from './routes/seller/Seller';
import { SellerForm } from './routes/seller/SellerForm';
import { User } from './routes/user/User';
import { UserForm } from './routes/user/UserForm';
import { ResetPasswordForm } from './routes/user/ResetPasswordForm';
import { Role } from './routes/role/Role';
import { RoleForm } from './routes/role/RoleForm';
import { WhatsApp } from './routes/whatsapp/WhatsApp';
import { Login } from './routes/login/index';
import { Dashboard } from './routes/dashboard/dashboard';
import { Page403 } from './routes/page-403/index';
import { setToken, deleteToken, getToken } from './helpers/auth-helper';
import { UserProvider } from './userContext';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './themes/primaryTheme';
import { createBrowserHistory } from 'history';


import { CouponType } from './routes/couponType/CouponType';
import { Coupon } from './routes/coupon/Coupon';
import { CouponForm } from './routes/coupon/CouponForm';

import 'typeface-roboto';
import './custom.css'
import './assets/_widgets.scss';

function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to='/login' />}
        />
    )
}

const history = createBrowserHistory();

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logueaado: false,
            user: null,      
            logoutUser: this.logout.bind(this),
            loginUser: this.login.bind(this),
            menu:[],
        }


	}

    static displayName = App.name;

    login(data) {

        setToken(data.token);

        this.setState({
            logueaado: true,
            user: {
                ...this.state.user,
                isAuth: true,
                token: data.token,
                email: data.email,
                name: data.name
            }
        });

        this.GetMenu();
    }

    logout() {

        deleteToken();

        this.setState({
            logueaado: false,
            menu:[],
            user: {
                ...this.state.user,
                isAuth: false,
                token: '',
                email: '',
            }
        });       

        
        //history.push('/login');

    }

    async GetMenu() {
        var token = await getToken();


        await axios.get('/api/Home/GetMenu'
            , { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                this.setState({
                    menu: response.data
                });
            })
            .catch(() => {
                this.setState({
                    menu: []
                });
            });
	}

    async componentDidMount() {


        await axios.get('/api/auth/GetAppVersion')
            .then(response => {
                var serverVersion = response.data;
                var localVersion = localStorage.getItem('adminBiosintex');               

                if (localVersion === null) {
                    localStorage.setItem('adminBiosintex', serverVersion);
                    console.log('empty cookie version');
                    window.location.reload(true);
                }
                else {
                    if (serverVersion > localVersion) {
                        localStorage.setItem('adminBiosintex', serverVersion);
                        this.deleteCacheAndReloadPage();
                    }
                }




            })
            .catch(() => {
                console.log("Error getting version");
            });


        var token = await getToken();

        if (token === null) {

            this.setState({
                user: {
                    ...this.state.user,
                    isAuth: false,
                    email: '',
                    token: '',
                }
            });
        }
        else {    
            await axios.get('/api/auth/GetUserProfile'
                , { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    this.setState({
                        user: {
                            ...this.state.user,
                            isAuth: true,
                            email: response.data.email,
                            name: response.data.name
                        }
                    });
                })
                .catch(() => {
                    this.setState({
                        user: {
                            ...this.state.user,
                            isAuth: false,
                            email: '',
                            token: '',
                        }
                    });
                });


            await this.GetMenu();
		}

    }

    async deleteCacheAndReloadPage() {
        console.log('new version2');

        if (caches) {

            const names = await caches.keys();
            console.log(names);

            await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
    }

    render() {

        const userValue = {
            user: this.state.user,
            logoutUser: this.logout,
            loginUser: this.login,
        }
        


        if (this.state.user === null) { return null }

        return (
            <MuiThemeProvider theme={theme}>

                <UserProvider value={this.state}>
                 
                    <Layout menu={this.state.menu}>


                        <Route path={`${process.env.PUBLIC_URL}/login`} name="Inicie sesi�n" component={Login} />
                        <PrivateRoute authed={this.state.user.isAuth} exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Pedidos`}  component={Order} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/VerPedido/:value`}  component={OrderForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/cliente`}  component={Client} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/ver-usuario/:value`} component={ClientForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Productos`} component={Product} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevoProducto`} component={ProductForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoProducto/:value`} component={ProductForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/FamiliasDeProductos`} component={ProductCategory} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevaFamiliaDeProducto`} component={ProductCategoryForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoFamiliaProducto/:value`} component={ProductCategoryForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Marcas`} component={Brand} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevaMarca`} component={BrandForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoMarca/:value`} component={BrandForm} />
     
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Ofertas/`} component={Discount} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevaOferta/`} component={DiscountForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoOferta/:value`} component={DiscountForm} />

                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/OfertasWhatsApp/`} component={OfferWhatsApp} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevaOfertaWhatsApp/`} component={OfferWhatsAppForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoOfertaWhatsApp/:value`} component={OfferWhatsAppForm} />

                                                                        
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Precios/`} component={Price} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Vendedores`} component={Seller} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevoVendedor`} component={SellerForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoVendedor/:value`} component={SellerForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Textos/`} component={SiteText} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevoTexto/`} component={SiteTextForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoTexto/:value`} component={SiteTextForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Slider/`} component={Slider} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevoSlider/`} component={SliderForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoSlider/:value`} component={SliderForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Usuarios`} component={User} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevoUsuario`} component={UserForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoUsuario/:value`} component={UserForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/resetearpassword/:value`} component={ResetPasswordForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Perfiles`} component={Role} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevoPerfil`} component={RoleForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoPerfil/:value`} component={RoleForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/Droguerias`} component={drugstore} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/NuevaDrogueria`} component={drugstoreForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/EditandoDrogueria/:value`} component={drugstoreForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/error-403/`} component={Page403} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/whatsapp-link/`} component={WhatsApp} />              
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/dashboard/`} component={Dashboard} />              
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/tipos-de-cupones/`} component={CouponType} />              

                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/cupones`} component={Coupon} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/nuevo-cupon`} component={CouponForm} />
                        <PrivateRoute authed={this.state.user.isAuth} path={`${process.env.PUBLIC_URL}/editando-cupon/:value`} component={CouponForm} />
                    </Layout>
                        
                </UserProvider>
            </MuiThemeProvider>
    );
  }
}
