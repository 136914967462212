import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import { Visibility, Save } from '@material-ui/icons/';
import { CustomDataTableExpanded } from '../../components/UI/CustomDataTableExpanded';

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import store from '../../store';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
export class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],            
            tabValue: '0',
            dialogOpen: false,


            selectedOrder: null,
            ordersStates: [],
            orderStateID:'',
        };

        this.refStoreTable = React.createRef();
        this.refFarmanobelDeliveryTable = React.createRef();
        this.refCorreoArgentinoTable = React.createRef();
        this.refOcaTable = React.createRef();
    } 
    
    async componentDidMount() {

        const ordersStatesResponse = await axiosInstance.get('/api/Order/GetOrdersStatesForSelect');

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },

            {
                name: 'Fecha',
                selector: 'date',
                sortable: true,
            },

         
            {
                name: 'Colaborador',
                selector: 'clientName',
                sortable: true,
             

            },


          

        

            {
                name: 'Punto de retiro',
                selector: 'retirePoint',
                sortable: true,
            },




        

            //{
            //    name: 'Pago',
            //    selector: 'paymentStatus',
            //    sortable: true,
            //    hidden: 'sm',
            //    maxwidth: '90px',
            //    cell: d => d.paymentStatus === 'approved' ? <Done style={{ color: 'green' }} /> : <CancelIcon color="secondary" />
            //},

            {
                name: 'SubTotal',
                selector: 'subTotal',
                sortable: true,
            },

            {
                name: 'Descuento',
                selector: 'save',
                sortable: true,
            },

           
            {
                name: 'Total',
                selector: 'total',
                sortable: true,
            },

       

            

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/VerPedido/" + row.id}>
                        <Tooltip title="Ver pedido">
                            <IconButton aria-label="Editar">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '90px',
            },       
           
        ];

     

        this.setState({
            columns: columns,                             
            ordersStates: ordersStatesResponse.data,
        });

      
    }

    renderOrdersStatesOptions() {
        return this.state.ordersStates.map((dt, i) => {
            return (


                <MenuItem
                    label="Seleccione un estado"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>

            );
        });
    }

    handleClickDialogOpen (selectedRow){

        console.log("selectedRow: " , selectedRow);

        this.setState({
            selectedOrder: selectedRow, 
            orderStateID: selectedRow.stateID,
            dialogOpen: true,
        })
    };

    handleDialogClose = (event, newValue) => {

        this.setState({
            dialogOpen: false,
        })
    };

    onChange = (event, value) => {

        this.setState({
            [event.target.name]: event.target.value
        })
    }



    async executeRESTCall() {

        let formData = {
            orderID: this.state.selectedOrder.id,
            orderStateID: this.state.orderStateID,
        };

        const response = await fetch('/api/Order/UpdateOrderState', {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status == 400) {
            const jsonResponse = await response.json();
            store.dispatch({
                type: "SNACKBAR_SUCCESS",
                message: jsonResponse.message
            })
        }


        if (response.status == 200) {

            this.setState({
                dialogOpen: false,
            });

      

            store.dispatch({
                type: "SNACKBAR_SUCCESS",
                message: "Operación exitosa"
            })

            switch (this.state.tabValue) {
                case "0":
                    this.refStoreTable.current.refreshDataTable();
                    break;
                case "1":
                    this.refFarmanobelDeliveryTable.current.refreshDataTable();
                    break;
                case "2":
                    this.refCorreoArgentinoTable.current.refreshDataTable();
                    break;
                case "3":
                    this.refOcaTable.current.refreshDataTable();
                    break;
            }

        }

    }   

    onSubmit = e => {
  
        this.executeRESTCall();

        e.preventDefault();
    }


    handleExcelClick(e) {

        axiosInstance({
            method: 'GET',
            url: '/api/Order/ExportOrders',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pedidosOpenLabColaboradores.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }




    render() {

        var dialogOpen = this.state.dialogOpen;

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Pedidos
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Biosintex24Hs
                                </Typography>
                                    <Typography color="textPrimary">Pedidos</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={2}>
                      

                    
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    size="small"
                    //startIcon={<GetApp />}
                    onClick={this.handleExcelClick}
                    type="button"
                    style={{ backgroundColor: '#217346', color: '#FFF', margin: '10px 0px 10px 0px' }}
                >
                    Generar archivo excel
                            </Button>


                <hr />

                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">{"Estado del pedido"}</DialogTitle>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                    >
                    <DialogContent>
               
                            <p>
                                Pedido: # {this.state.selectedOrder !== null ? this.state.selectedOrder.id : null}
                                </p>

                            <SelectValidator
                                style={{ width: "100%" }}
                                name="orderStateID"
                                variant="outlined"
                                size="small"
                                label="Estado"
                                value={this.state.orderStateID}
                                validators={['required']}
                                errorMessages={['El estado es requerido']}
                                onChange={this.onChange}>
                                {this.renderOrdersStatesOptions()}

                            </SelectValidator>
                    </DialogContent>

                    <DialogActions>
                 
                        <Button type="submit" color="primary" autoFocus>
                            <Save /> Guardar
                        </Button>

                        <Button onClick={this.handleDialogClose} color="default">
                            Cancelar
                        </Button>


                    </DialogActions>
                        </ValidatorForm>
                </Dialog>


         

                 
                       

                    <CustomDataTableExpanded
                        url="api/Order/GetStoreOrders"
                        columns={this.state.columns}
                        handleOpenModal={this.handleOpenModal}
                            ref={this.refStoreTable}
                        showHeader={true}
                    />
               




       

            </div>
        );
    }
}
