import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';

export class CouponForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ID: 0,
            code: '',
            description: '',
            discountPercentage: '',
            validFrom: null,
            validUntil: null,
            enabled: false,

            couponTypeID: 0,
            couponTypes: [],

            drugstoreID: '',
            drugstores: [],

        };;


        this.ID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {

        const url = '/api/coupon/';

        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/cupones');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })                   
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/cupones');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            if (event.target.name === "name") {
                var str = event.target.value;
                str = str.replace(/\s+/g, '-').toLowerCase();

                this.setState({
                    [event.target.name]: event.target.value,
                    url: str,
                })
            }


            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    async componentDidMount() {

        const couponTypesResponse = await axiosInstance.get(`/api/CouponType/GetCouponTypeForSelect`);
        const drugstoreREsponse = await axiosInstance.get(`/api/Drugstore/GetDrugstoresForSelect`);

        this.setState({
            couponTypes: couponTypesResponse.data,
            drugstores: drugstoreREsponse.data,

        });


        if (this.isEdit) {
            await axiosInstance.get(`api/coupon/GetByID/${this.ID}`).then(
                response => {
                    this.setState({
                        ID: response.data.id,
                        description: response.data.description,
                        code: response.data.code,
                        couponTypeID: response.data.couponTypeID,
                        discountPercentage: response.data.discountPercentage,
                        enabled: response.data.enabled,
                        validFrom: response.data.validFrom,
                        validUntil: response.data.validUntil,
                        drugstoreID: response.data.drugstoreID,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando cupón';
        }
        else {
            title ='Crea un nuevo cupón';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Precios y ofertas
                                        </Typography>

                                    <Link color="inherit" to="/cupones" >
                                        <Typography color="textPrimary">Cupones</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/cupones">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }



    renderDrugstoresOptions() {
        return this.state.drugstores.map((dt, i) => {
            return (


                <MenuItem
                    label="Seleccione una droguería"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>

            );
        });
    }



    renderCouponTypesOptions() {
        return this.state.couponTypes.map((dt, i) => {
            return (


                <MenuItem
                    label="Seleccione un tipo de cupóon"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>

            );
        });
    }


    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                        <TextValidator id="description"
                                                style={{ width: "100%" }}
                                                name="description"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="code"
                                            style={{ width: "100%" }}
                                            name="code"
                                            label="Código"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.code}
                                            validators={['required', 'minStringLength:7','maxStringLength:7']}
                                            errorMessages={['El código es requerido', 'Mínimo 7 carácteres', 'Máximo 7 carácteres']}
                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                            autoComplete="off"
                                        />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="couponTypeID"
                                                variant="outlined"
                                                size="small"
                                                label="Tipo de cupón"
                                                value={this.state.couponTypeID}
                                                validators={['required']}
                                                errorMessages={['El tipo de cupón es requerido']}
                                                onChange={this.onChange}>
                                                {this.renderCouponTypesOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>            

                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="drugstoreID"
                                                variant="outlined"
                                                size="small"
                                                label="Droguería"
                                                value={this.state.drugstoreID}
                                                onChange={this.onChange}>
                                                {this.renderDrugstoresOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>           

                                  

                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="discountPercentage"
                                            style={{ width: "100%" }}
                                            name="discountPercentage"
                                            label="Porcentaje de descuento"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.discountPercentage}
                                            validators={['required', 'matchRegexp:^[0-9]+(\.[0-9]{1,2})?$']}
                                            errorMessages={['El porcentaje de desuento es requerido', 'Solo números']}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <TextValidator
                                            InputLabelProps={{ shrink: true }}
                                            label="Válido desde"
                                            className="iron-form-input-wrap"
                                            onChange={this.onChange}
                                            name="validFrom"
                                            type="date"
                                            value={this.state.validFrom}
                                            size="small"
                                            placeholder="DD/MM/YYYY"
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={['El válido desde es requerido']}
                                        />
                                    </Grid>

                              


                                    <Grid item sm={12} xs={12}>



                                        <TextValidator
                                            InputLabelProps={{ shrink: true }}
                                            label="Válido hasta"
                                            className="iron-form-input-wrap"
                                            onChange={this.onChange}
                                            name="validUntil"
                                            type="date"
                                            value={this.state.validUntil}
                                            size="small"
                                            placeholder="DD/MM/YYYY"
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={['El válido hasta es requerido']}
                                        />

                                    </Grid>




                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}