import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { Paper, Button, CircularProgress, IconButton } from '@material-ui/core';
import { Save, Search, Edit } from '@material-ui/icons/';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { getToken, initAxiosInterceptors } from '../../helpers/auth-helper'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Pharmacy } from './Pharmacy';
import Alert from '@material-ui/lab/Alert'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export class ClientForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
            
            client: null,


            userID: 0,
            sellerCode: '',
            codePlataforma: '',
            userStatusID: 0,
            cuit:'',

            pharmacy: [],
            drugstores: null,
            isSubmitting: false,

            cuitDialogOpen: false,

        };

        this.clientID = this.props.match.params.value;

        this.onSubmit = this.onSubmit.bind(this);
        this.searchClientByCuit = this.searchClientByCuit.bind(this);
        this.openCuitDialog = this.openCuitDialog.bind(this);
        this.handleCloseCuitdialog = this.handleCloseCuitdialog.bind(this);
        this.onSubmitCuit = this.onSubmitCuit.bind(this);
        
    }

    handleChange = (event, value) => {

     
        console.log(event);


            this.setState({
                [event.target.name]: event.target.value
            })
        
    }   

    async componentDidMount() {

        var token = getToken();
        await initAxiosInterceptors();

        await axios.get(`api/Client/GetByID/${this.clientID}`).then(
            response => {
                this.setState({
                    client: response.data,
                    userID: response.data.id,
                    cuit: response.data.cuit,
                    email: response.data.email,
                    userStatusID: response.data.userStatusID,
                    codePlataforma: response.data.codePlataforma === null ? '' : response.data.codePlataforma,
                    sellerCode: response.data.sellerCode === null ? '' : response.data.sellerCode,
                    drugstores: response.data.drugStores 
                });
            });
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    async onSubmit(e)  {

        let formData = {
            userStatusID: this.state.userStatusID,
            userID: this.state.userID,
            codePlataforma: this.state.codePlataforma,
            sellerCode: this.state.sellerCode,
            drugstores: this.state.drugstores,
            email: this.state.email,
            cuit:this.state.cuit,
		}

        await axios.put('/api/client/UpdateClient/', formData)
            .then(response => {

                this.props.history.push('/cliente');
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });

        e.preventDefault();
    }

    async onSubmitCuit(e) {

        let formData = {
            userID: this.state.userID,
            cuit: this.state.cuit,
        }

        await axios.put('/api/client/UpdateClientCUIT/', formData)
            .then(response => {

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });

        this.handleCloseCuitdialog();

        e.preventDefault();
    }


    async searchClientByCuit() {

        this.setState({
            isSubmitting: true,
        });


        await axios.get('/api/client/SearchClientByCuit/' + this.state.client.id+'')
            .then(response => {

                console.log("response: ",  response.data);

                if (response.data.isOk) {
                    this.setState({
                        pharmacy: response.data.clientes.clientes,
                    })


                    if (response.data.clientes.clientes.length == 0) {
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "No se encontraron farmacias con este CUIT"
                        })
					}
                }


                this.setState({
                    isSubmitting: false,
				})



            }).catch(error => {

                this.setState({
                    isSubmitting: false,
                })



                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });
    }

    selectPharmacyCallbackFunction = (data) => {

        console.log("DATAAA: ", data);

        if (data.clientData !== null) {
            this.setState({
                codePlataforma: data.pharmacy.clienteID
                , sellerCode: data.clientData.sellerCode
                , drugstores: data.clientData.drugstores
            }, () => {
                //this.updateConfirmButton();
                //this.setStepFocus();
            });
        }
    }


    openCuitDialog() {
        this.setState({
            cuitDialogOpen: true, 
		})
    }

    handleCloseCuitdialog() {
        this.setState({
            cuitDialogOpen: false,
        })
    }

    render() {

        const { client } = this.state;
      

        return (

            <div>


                <Dialog onClose={this.handleCloseCuitdialog} aria-labelledby="simple-dialog-title" open={this.state.cuitDialogOpen}>
                    <DialogTitle id="simple-dialog-title">Editar CUIT</DialogTitle>
                        <ValidatorForm ref="form"
                            onSubmit={this.onSubmitCuit}
                            onError={errors => console.log(errors)}
                        >
                            <DialogContent>
                                <TextValidator
                                    style={{
                                        width: "100%"
                                    }}
                                    id="cuit"
                                    name="cuit"
                                    label="CUIT"
                                    variant="outlined"
                                    size="small"
                                    onChange={this.handleChange}
                                    value={this.state.cuit}
                                    validators={['required']}
                                    errorMessages={['El cuit es requerido']}
                                    autoComplete="off"
                                    />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={this.handleCloseCuitdialog} color="primary">
                                    Cancelar
                                </Button>
                                <Button type="submit" color="primary" autoFocus>
                                    Editar
                                </Button>
                            </DialogActions>
                        </ValidatorForm>
                </Dialog>

                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <h4>
                               Datos del usuario
                                <small>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Typography color="inherit">
                                        </Typography>


                                    </Breadcrumbs>
                                </small>
                            </h4>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Link to="/cliente">
                                <Button
                                    variant="default"
                                    color="default"
                                    size="small"
                                    startIcon={<CancelIcon />}
                                    style={this.CreateStyles()}
                                >
                                    Volver
                        </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <hr />
                </div>

                {this.state.client === null ? "" :


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h5>
                                    Cliente #{client.id}
                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}> <i>Fecha de alta: {client.createdDate}</i></small>

                                </h5>

                                <hr />


                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={8} lg={8}>

                                        <h5>   {client.lastName}, {client.firstName}</h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                <b>Teléfono: </b>({client.areaCode}) {client.phoneNumber}
                                            </li>
                                            <li>
                                                <b>Correo: </b> {client.email}
                                            </li>
                                      
                                         

                                            <li>
                                                <b>Nro documento: </b> {client.documentNumber}
                                            </li>

                                         
                                        </ul>
                                    </Grid>

                                </Grid>

                                <hr />

                                <ValidatorForm ref="form"
                                    onSubmit={this.onSubmit}
                                    onError={errors => console.log(errors)}
                                >
                                <Grid container spacing={2} >

                                        <Grid item xs={12} sm={12} md={12} lg={12}>


                                            <Grid container spacing={2} >


                                              

                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                        id="email"
                                                        name="email"
                                                        label="Email"
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.handleChange}
                                                        value={this.state.email}
                                                        validators={['required']}
                                                        errorMessages={['El email es requerido']}
                                                        autoComplete="off"
                                                    />
                                                </Grid>

                                             

                                                <Grid item xs={12}>

                                                    <SelectValidator                                            
                                                        id="userStatusID"
                                                        name="userStatusID"
                                                        label="Estado"
                                                        style={{ width: "100%" }}
                                                        value={this.state.userStatusID}
                                                        onChange={this.handleChange}
                                                        variant="outlined"
                                                        size="small"
                                                        >
                                                        <MenuItem value={1}>Pendiente de aprobación</MenuItem>
                                                        <MenuItem value={2}>Aprobado</MenuItem>
                                                        <MenuItem value={3}>Deshabilitado</MenuItem>
                                                    </SelectValidator>
                                                </Grid>

                                           


                                          

                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<Save />}
                                                        type="submit"
                
                                                    >
                                                        Guardar
                                                    </Button>
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                </Grid>

                            </ValidatorForm>

                                


                           


                            </Paper>
                        </Grid>
                      

                    </Grid>
                }
            </div>
        )
    }
}