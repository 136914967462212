import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Visibility, Edit, AddCircleOutline } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Grid from '@material-ui/core/Grid';
import store from '../../store';
import swal from 'sweetalert'



export class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 
    
    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '80px',
                hide: 'sm',
                cell: row =>

                    <div style={{ paddingTop: '8px' }}>
                        <b>{row.id}</b>
                        <Tooltip title="ID plataforma" placement="top">
                            <p style={{ color: '#666' }}> {row.codePlataforma} </p>

                        </Tooltip>
                    </div>
                ,
            },

         

            {
                name: 'Fecha',
                selector: 'createdDate',
                width: '100px',

                sortable: true,
            },


            {
                name: 'Colaborador',
                selector: 'lastName',
                sortable: true,
                

                cell: row =>
                   
                     
                        <p style={{ color: '#111', marginTop:'12px' }}> {row.lastName.toUpperCase() + ", " + row.firstName} </p>
             
                ,

            },

          

         
        
            {
                name: 'Correo',
                selector: 'email',
                sortable: true,
            },
          
            {
                name: 'DNI',
                selector: 'documentNumber',
                sortable: true,
            },
                {
                name: 'Estado',
                selector: 'status',
                sortable: true,
            },

         
            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/ver-usuario/" + row.id}>
                        <Tooltip title="Ver usuario">
                            <IconButton aria-label="Editar">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '90px',
            },       
           
        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    


    handleExcelClick(e) {

        axiosInstance({
            method: 'GET',
            url: '/api/Client/ExportClients',
            responseType: 'blob',         
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'colaboradores.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

	}

   

   
    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Colaboradores 
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Biosintex24Hs
                                </Typography>
                                    <Typography color="textPrimary">Colaboradores</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={2}>

                    
                    </Grid>
                </Grid>


              
                    <Button
                        variant="contained"
                        size="small"
                        //startIcon={<GetApp />}
                        onClick={this.handleExcelClick}
                        type="button"
                        style={{ backgroundColor: '#217346', color: '#FFF', margin: '10px 0px 10px 0px' }}
                    >
                        Generar archivo excel
                            </Button>
              

                <hr/>

                <CustomDataTable
                    url="api/Client"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
